.our-url {
    background-color: white;
    border: 2px solid black;
    border-radius: 20px;
    padding: 0px 20px 0px 20px;
}

.pageContainer {
    padding-left: 30px;
    text-align: left;
}

.searchResults {
    text-align: left;
}

.yelp-ad {
    color: red;
    font-weight: bold;
}

.timestamp {
    font-weight: bold;
}
