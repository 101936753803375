.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

#login-success {
  text-align: center;
  color: green;
  padding-top: 70px;
}

@media (min-width: 768px) {
  .navbar-right {
    float: right !important;
    margin-right: -15px;
  }
}
