.line-chart {
    margin-top: 30px !important;
}

.chart-message {
    margin-top: 30px;
    color: red;
}

.report-title {
    margin-top: 10px;
}
