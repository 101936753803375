.Home .lander {
    padding: 40px 0;
    text-align: center;
    margin-top: 40px;
}

.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #333;
    font-weight: bold;
}

.logo-gld {
    color: #f2b90d;
    font-weight: bold;
}
