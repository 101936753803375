body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

#login-success {
  text-align: center;
  color: green;
  padding-top: 70px;
}

@media (min-width: 768px) {
  .navbar-right {
    float: right !important;
    margin-right: -15px;
  }
}

.Home .lander {
    padding: 40px 0;
    text-align: center;
    margin-top: 40px;
}

.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #333;
    font-weight: bold;
}

.logo-gld {
    color: #f2b90d;
    font-weight: bold;
}

.NotFound {
    padding-top: 100px;
    text-align: center;
}

.Google {
    padding-top: 40px;
    text-align: center;
}

.reportHeader {
    text-align: left;
}

.our-url {
    background-color: white;
    border: 2px solid black;
    border-radius: 20px;
    padding: 0px 20px 0px 20px;
}

.pageContainer {
    padding-left: 30px;
    text-align: left;
}

.searchResults {
    text-align: left;
}

.Yelp {
    padding-top: 40px;
    text-align: center;
}

.reportHeader {
    text-align: center;
    /* background-color: #F8F8F8; */
    border-color: #E7E7E7;
    margin-top: 5px;
}

.form-group {
    margin-right: 10px;
}

.control-label {
    margin-right: 5px;
}

.controls-container {
    margin-top: 15px;
}

p.controls-info {
    /* font-weight: bold; */
}

.our-url {
    background-color: white;
    border: 2px solid black;
    border-radius: 20px;
    padding: 0px 20px 0px 20px;
}

.pageContainer {
    padding-left: 30px;
    text-align: left;
}

.searchResults {
    text-align: left;
}

.yelp-ad {
    color: red;
    font-weight: bold;
}

.timestamp {
    font-weight: bold;
}

.line-chart {
    margin-top: 30px !important;
}

.chart-message {
    margin-top: 30px;
    color: red;
}

.report-title {
    margin-top: 10px;
}

.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

@media all and (min-width: 480px) {
    .Signup {
        padding: 60px 0;
    }

    .Signup form {
        margin: 0 auto;
        max-width: 320px;
    }
}

.Signup form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
}

#signup-container {
    margin-top: 50px;
}

#login-container {
    margin-top: 50px;
}

@media all and (min-width: 480px) {
    .Login {
        padding: 100px 0;
    }

    .Login form {
        margin: 0 auto;
        max-width: 320px;
    }
}

#Logout {
    padding-top: 70px;
    text-align: center;
    color: green;
}

.Welcome {
    padding-top: 40px;
    text-align: center;
}

.Settings {
    padding-top: 40px;
    text-align: center;
}

.line-chart {
    margin-top: 100px;
}

