.Yelp {
    padding-top: 40px;
    text-align: center;
}

.reportHeader {
    text-align: center;
    /* background-color: #F8F8F8; */
    border-color: #E7E7E7;
    margin-top: 5px;
}

.form-group {
    margin-right: 10px;
}

.control-label {
    margin-right: 5px;
}

.controls-container {
    margin-top: 15px;
}

p.controls-info {
    /* font-weight: bold; */
}
